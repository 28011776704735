<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      ip: null,

      list: null,

      create: {
        modal: false,
        loading: false,
        number: "",
        expiryMonth: "",
        expiryYear: "",
        cvv: "",
        holderName: "",
      },

      del: {
        modal: false,
        loading: false,
        card: null,
      },

      primary: {
        modal: false,
        loading: false,
        card: null,
      },
    };
  },
  validations: {
    create: {
      number: { required },
      expiryMonth: { required },
      expiryYear: { required },
      cvv: { required },
      holderName: { required },
    },
  },
  methods: {
    getIp() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          if (data.ip) {
            this.ip = data.ip;
          }
        });
    },
    getCreditCards() {
      this.list = null;

      api
        .get('payments/creditcards')
        .then((response) => {
          if (response.data.status == 'success') {
            this.list = response.data.list;
          }
        })
    },
    addCreditCard() {
      this.create.modal = false;
      this.create.modal = true;
    },
    createCreditCard() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.create.loading = true;

        api
          .post('payments/creditcards', {
            number: this.create.number,
            expiryMonth: this.create.expiryMonth,
            expiryYear: this.create.expiryYear,
            cvv: this.create.cvv,
            holderName: this.create.holderName,
            ip: this.ip
          })
          .then((response) => {
            if (response.data.status == 'success') {
              this.create.number = "";
              this.create.expiryMonth = "";
              this.create.expiryYear = "";
              this.create.cvv = "";
              this.create.holderName = "";

              this.create.modal = false;
              this.create.loading = false;

              this.$toast.success("O cartão de crédito foi cadastrado com sucesso.");
              this.getCreditCards();
            } else {
              this.create.loading = false;
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.create.modal = false;
              this.create.loading = false;
            }
          })
          .finally(() => {
          });
      }
    },
    goDelete(row) {
      this.del.card = row;
      this.del.modal = false;
      this.del.modal = true;
    },
    deleteCreditCard() {
      if (this.del.card && this.del.card.id) {
        this.del.loading = true;

        api
          .delete('payments/creditcards/' + this.del.card.id)
          .then((response) => {
            if (response.data.status == 'success') {
              this.del.modal = false;
              this.del.loading = false;
              this.del.card = null;

              this.$toast.success("O cartão de crédito foi excluído com sucesso.");
              this.getCreditCards();
            } else {
              this.del.loading = false;
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error) {
              this.del.modal = false;
              this.del.loading = false;
            }
          })
          .finally(() => {
          });
      }
    },
    goPrimary(row) {
      this.primary.modal = false;
      this.primary.modal = true;
      this.primary.card = row;
    },
    primaryCreditCard() {
      if (this.primary.card && this.primary.card.id) {
        this.primary.loading = true;
        api
          .put('payments/creditcards/primary/' + this.primary.card.id)
          .then((response) => {
            if (response.data.status == 'success') {
              this.primary.modal = false;
              this.primary.card = null;

              this.$toast.success("A forma de pagamento principal foi alterada com sucesso.");
              this.getCreditCards();
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch(() => {
          })
          .finally(() => {
            this.primary.loading = false;
          });
      }
    }
  },
  mounted() {
    this.getIp();
    this.getCreditCards();
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Pagamentos</h3>
        <p>Gerencie seus métodos de pagamento.</p>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Ações
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="addCreditCard()">Adicionar</b-dropdown-item>
      </b-dropdown>
    </div>

    <div v-if="!list" class="text-center">
      <b-spinner variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="list && list.length == 0" class="card">
      <div class="card-body text-center">
        Nenhum cartão de crédito cadastrado.
      </div>
    </div>

    <div v-else class="card" v-for="(row, index) in list" :key="index">
      <div class="card-body">
        <div class=" d-flex justify-content-between">
          <div>
            <h6 class="text-uppercase" v-if="row.type == 'primary'">Principal</h6>
            <h6 class="text-uppercase" v-else>Reserva</h6>
            <p class="m-0 font-size-15">Terminando em {{ row.number }}</p>
            <p class="m-0 font-size-15">Expira em {{ row.expires }}</p>
          </div>
          <div>
            <button class="btn btn-link text-default p-0" v-on:click="goDelete(row)">Excluir</button>
          </div>
        </div>
        <div class="d-inline-block bg-danger rounded px-2 mt-2 font-size-13 text-uppercase text-white" v-if="row.status == 'refused'">
          Cartão recusado
        </div>
        <div v-if="row.type == 'backup' && row.status == 'active'" class="mt-3">
          <button class="btn btn-link p-0 text-default" v-on:click="goPrimary(row)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(17, 47, 71, 1);">
              <path d="M7 5c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2H7zm0 12V7h10l.002 10H7z"></path>
            </svg>
            Tornar principal
          </button>
        </div>
      </div>
    </div>

    <b-modal v-model="create.modal" modal-class="modal-right" hide-header hide-footer>
      <b-form @submit.prevent="createCreditCard">
        <h4>Adicionar cartão de crédito</h4>
        <p></p>
        <div class="my-5">
          <b-form-group label="Número" label-for="number">
            <b-form-input id="number" v-model="create.number" type="text" required v-mask="'#### #### #### ####'"></b-form-input>
          </b-form-group>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Validade" label-for="expiryDate">
                <b-input-group>
                  <b-form-input id="expiryMonth" v-model="create.expiryMonth" type="text" placeholder="Mês" maxlength="2" required v-mask="'##'"></b-form-input>
                  <b-form-input id="expiryYear" v-model="create.expiryYear" type="text" placeholder="Ano" maxlength="2" required v-mask="'##'"></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="CVV" label-for="cvv">
                <b-form-input id="cvv" v-model="create.cvv" type="text" required v-mask="'###'"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <b-form-group label="Nome do titular" label-for="holderName">
            <b-form-input id="holderName" v-model="create.holderName" type="text" required></b-form-input>
          </b-form-group>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-default btn-lg mr-3" type="submit" :disabled="this.create.loading">
            Adicionar
            <b-spinner v-if="create.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <a class="btn btn-outline-link" v-on:click="create.modal = false">Fechar</a>
        </div>
      </b-form>
    </b-modal>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <b-form v-if="del.card" @submit.prevent="deleteCreditCard">
        <h4 v-if="del.card.type == 'primary'">Tem certeza de que deseja excluir a sua forma de pagamento principal?</h4>
        <h4 v-else>Tem certeza de que deseja excluir a sua forma de pagamento de reserva?</h4>
        <p v-if="del.card.type == 'primary'">A sua forma de pagamento de reserva se tornará a forma de pagamento principal.</p>

        <div class="mt-4 mb-5">
          <p class="m-0 font-size-15">Terminando em {{ del.card.number }}</p>
          <p class="font-size-15">Expira em {{ del.card.expires }}</p>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-danger btn-lg mr-3" type="submit" :disabled="this.del.loading">
            Excluir
            <b-spinner v-if="del.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <a class="btn btn-outline-link" v-on:click="del.modal = false">Fechar</a>
        </div>
      </b-form>
    </b-modal>

    <b-modal v-model="primary.modal" modal-class="modal-bottom" hide-header hide-footer>
      <b-form v-if="primary.card" @submit.prevent="primaryCreditCard">
        <h4>Tem certeza de que deseja alterar o seu cartão de crédito principal?</h4>

        <div class="mt-4 mb-5">
          <p class="m-0 font-size-15">Terminando em {{ primary.card.number }}</p>
          <p class="font-size-15">Expira em {{ primary.card.expires }}</p>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-default btn-lg mr-3" type="submit" :disabled="this.primary.loading">
            Alterar
            <b-spinner v-if="primary.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
          <a class="btn btn-outline-link" v-on:click="primary.modal = false">Fechar</a>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>